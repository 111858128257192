<template>
  <v-tabs
    grow
    show-arrows
    v-model="tab"
    background-color="grey"
    color="white"
  >
    <v-tab
      v-for="d in disciplines"
      :key="d._id"
      :href="`#dev-${d._id}`"
    >
      {{ d.order }}. {{ d.name }}
    </v-tab>

    <v-tab-item
      v-for="d in disciplines"
      :key="d._id"
      :value="`dev-${d._id}`"
    >
      <geraet :id="id" :did="d._id" :e="e" :r="r" :df="df" :f="f" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'ergebnisse',

  components: {
    Geraet: () => import('./ergebnisse/geraet')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tab: -1
  }),

  computed: {
    disciplines () {
      return (this.df?.map(d => ({
        ...d,
        __typename: undefined,
        active: !!this.e?.disciplines?.find(ed => ed._discipline === d._id),
        order: this.e?.disciplines?.find(ed => ed._discipline === d._id)?.order
      })) || [])
        .filter(d => d.active && d.regular)
        .sort((a, b) => {
          return a.order < b.order ? -1 : 1
        })
    }
  },

  watch: {
    disciplines () {
      if (this.tab === -1) {
        this.tab = this.disciplines?.[0]?._id || null
      }
    }
  }
}
</script>

<style scoped>

</style>
